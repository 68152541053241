import React from "react"
import alvaro from "../../assets/images/team/alvaro.png"
import araceli from "../../assets/images/team/araceli.png"
import barbara from "../../assets/images/team/barbara.png"
import braulio from "../../assets/images/team/braulio.png"
import carolina from "../../assets/images/team/carolina.png"
import claudio from "../../assets/images/team/claudio.png"
import daniel from "../../assets/images/team/daniel.png"
import felipe from "../../assets/images/team/felipe.png"
import giovanni from "../../assets/images/team/giovanni.png"
import kevin from "../../assets/images/team/kevin.png"
import letty from "../../assets/images/team/letty.png"
import leandro from "../../assets/images/team/leandro.png"
import matias from "../../assets/images/team/matias.png"
import michel from "../../assets/images/team/michel.png"
import nadia from "../../assets/images/team/nadia.png"
import nayarett from "../../assets/images/team/nayarett.png"
import ninosca from "../../assets/images/team/ninosca.png"
import pia from "../../assets/images/team/pia.png"
import ronald from "../../assets/images/team/ronald.png"
import rodrigo from "../../assets/images/team/rodrigo.png"
import romina from "../../assets/images/team/romina.png"
import vanessa from "../../assets/images/team/vanessa.png"
import vanerey from "../../assets/images/team/vanerey.png"
import veronica from "../../assets/images/team/veronica.png"

const NuestroEquipo = () => {
  const areas = [
    "Mesa de Ayuda",
    "Área Comercial",
    "Operaciones",
    "Proyectos",
    "Desarrollo",
    "Aseguramiento de Calidad",
    "Dirección",
  ]
  const equipos = {
    "Mesa de Ayuda": [
      {
        nombre: "Nayarett Díaz",
        cargo: "Atención a clientes",
        img: nayarett,
      },
      {
        nombre: "Rodrigo Durán",
        cargo: "Atención a clientes",
        img: rodrigo,
      },
      {
        nombre: "Araceli Salazar",
        cargo: "Atención a clientes",
        img: araceli,
      },
      {
        nombre: "Vanessa Bahamondes",
        cargo: "Líder de Atención a clientes",
        img: vanessa,
      },
    ],
    "Área Comercial": [
      {
        nombre: "Pía Ayala",
        cargo: "Marketing",
        img: pia,
      },
      {
        nombre: "Romina Valencia",
        cargo: "Ejecutiva Comercial",
        img: romina,
      },
      {
        nombre: "Bárbara Flores",
        cargo: "Ejecutiva comercial",
        img: barbara,
      },
      {
        nombre: "Vanessa Reyes",
        cargo: "Diseñadora Gráfica",
        img: vanerey,
      },
    ],
    Operaciones: [
      {
        nombre: "Nadia Rojas",
        cargo: "Líder de Operaciones",
        img: nadia,
      },
      {
        nombre: "Carolina Abarca",
        cargo: "Analista de Operaciones e Implementación",
        img: carolina,
      },
      {
        nombre: "Matías Muñoz",
        cargo: "Analista de Operaciones e Implementación",
        img: matias,
      },
      {
        nombre: "Leandro Pizarro",
        cargo: "Analista de Operaciones e Implementación",
        img: leandro,
      },
      {
        nombre: "Ronald Benavides",
        cargo: "Subgerente de Proyectos",
        img: ronald,
      },
    ],
    'Gestión de Calidad Organizacional' : [
      {
        nombre: "Giovanni Sanchez",
        cargo: "Consultor ISO",
        img: giovanni,
      }
    ],
    Desarrollo: [
      {
        nombre: "Daniel Olguín",
        cargo: "Ingeniero de Desarrollo",
        img: daniel,
      },
      {
        nombre: "Letty Villamizar",
        cargo: "Ingeniera de Desarrollo",
        img: letty,
      },
      {
        nombre: "Claudio Miranda",
        cargo: "Ingeniero de Desarrollo",
        img: claudio,
      },
      {
        nombre: "Alvaro Alvarez",
        cargo: "Ingeniero de Desarrollo",
        img: alvaro,
      },
      {
        nombre: "Kevin Martínez",
        cargo: "Ingeniero de Desarrollo",
        img: kevin,
      },
      {
        nombre: "Felipe Fuentes",
        cargo: "Ingeniero de Desarrollo",
        img: felipe,
      },
      {
        nombre: "Verónica Rodríguez",
        cargo: "Asistente de Gerencia TI",
        img: veronica,
      }
    ],
    "Aseguramiento de Calidad": [
      {
        nombre: "Ninosca Cifuentes",
        cargo: "Analista QA",
        img: ninosca,
      },
    ],
    Dirección: [
      {
        nombre: "Braulio Santibañez",
        cargo: "CEO",
        img: braulio,
      },
      {
        nombre: "Michel Muñoz",
        cargo: "CTO",
        img: michel,
      },
    ],
  }

  return (
    <section className="scientist-area bg-color pb-70">
      <div className="container">
        <div className="section-title">
          <h2>Nuestro Equipo</h2>
          <p>
            Contamos con un equipo grande, todos con distintas habilidades y
            conocimientos, lo que hace ver los problemas y oportunidades desde
            diferentes perspectivas.
          </p>
        </div>
        {Object.keys(equipos).map((key, index) => {
          return (
            <React.Fragment key={key}>
              <div className="section-title">
                <h3>{key}</h3>
              </div>
              <div className="row justify-content-center">
                {equipos[key].map((item, subindex) => {
                  return (
                    <div className="col-lg-3 col-sm-6 col-md-6" key={subindex}>
                      <div className="single-scientist-box">
                        <div className="image">
                          <img src={item.img} alt="about" />
                        </div>
                        <div className="content">
                          <h3>{item.nombre}</h3>
                          <span>{item.cargo}</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </section>
  )
}

export default NuestroEquipo
