import React from "react"
import { Link } from "gatsby"
import aboutImage from "../../assets/images/febos/quienes_somos.png"
import starIcon from "../../assets/images/star-icon.png"
import icon4 from "../../assets/images/icons/icon4.png"
import icon5 from "../../assets/images/icons/icon5.png"
import icon6 from "../../assets/images/icons/icon6.png"
import icon7 from "../../assets/images/icons/icon7.png"
import icono1 from "../../assets/images/febos/icono-1.png"
import icono2 from "../../assets/images/febos/icono-2.png"
import icono3 from "../../assets/images/febos/icono-3.png"
import icono4 from "../../assets/images/febos/icono-4.png"
import shape1 from "../../assets/images/shape/circle-shape1.png"

const aniosEmpresa = 10
const QuienesSomosContenido = () => {
  return (
    <section className="about-area">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={aboutImage} alt="banner" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <h2>Acerca de nosotros</h2>
                <ul className="features-list">
                  <li>
                    <img src={icono1} alt="banner"  width={70}/>
                    <h3>{aniosEmpresa} Años</h3>
                    <p>Experiencia en el sector privado</p>
                  </li>
                  <li>
                    <img src={icono2} alt="banner"  width={70}/>
                    <h3>{aniosEmpresa-2} Años</h3>
                    <p>Experiencia en el sector público</p>
                  </li>
                  <li>
                    <img src={icono3} alt="banner"  width={70}/>
                    <h3>20+</h3>
                    <p>Analistas e ingenieros</p>
                  </li>
                  <li>
                    <img src={icono4} alt="banner" width={70}/>
                    <h3>97%</h3>
                    <p>Cumplimiento SLA</p>
                  </li>
                </ul>
                <p>
                  Somos una empresa con más de {aniosEmpresa} años de experiencia en sector
                  público y privado, comprometidos con lo digital y haciendo que
                  las cosas pasen. Cumplimos con no dejar huella de carbono,
                  adaptándonos al futuro y haciendo que el mundo de los trámites
                  y papeleos, se base 100% en lo digital, permitiendo que
                  nuestros clientes se sientan cómodos, familiarizados con el
                  ecosistema tecnológico y facilitando los trámites
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="banner" />
      </div>

      <div className="container">
        <div className="about-inner-area">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Nuestra historia</h3>
                <p>
                  <strong>FEBOS</strong> nace con el fin de apoyar a las
                  empresas, desarrollando sistemas inteligentes y eficientes
                  administrativos, para así optimizar el ciclo comercial entre
                  clientes y proveedores, en donde ya no se manejan documentos
                  en papel, si no que todo se basa en lo digital.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Nuestra misión</h3>
                <p>
                  Efectuamos de forma electrónica toda la operación de
                  documentos, tales como: cotizaciones, órdenes de compra,
                  licitaciones, facturas, entre otros. Desarrollamos y
                  administramos una Comunidad de Negocios para fortalecer la
                  relación entre cliente-proveedor facilitando así, el
                  procedimiento de este.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
              <div className="about-text">
                <h3>Nuestra visión</h3>
                <p>
                  Posicionarnos dentro de las empresas más innovadoras y
                  eficientes en el desarrollo de Comercio Electrónico B2B en
                  Chile y que así nuestros usuarios y clientes puedan vernos
                  como un canal facilitador y un socio estratégico para los
                  nuevos cambios en el mundo digital.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="banner" />
      </div>
    </section>
  )
}

export default QuienesSomosContenido
