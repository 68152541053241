import React from "react"
import client1 from "../../assets/images/testimonials/client1.jpg"
import client2 from "../../assets/images/testimonials/client2.jpg"
import client3 from "../../assets/images/testimonials/client3.jpg"
import shape from "../../assets/images/shape/shape1.svg"

import Loadable from "@loadable/component"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  margin: 30,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 2,
    },
  },
}

const Testimonios = () => {
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <section className="testimonials-area bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <h2>¿Qué dicen nuestros clientes?</h2>
          <p>Algunos testimonios de nuestros clientes.</p>
        </div>

        {display ? (
          <OwlCarousel
            className="testimonials-slides owl-carousel owl-theme"
            {...options}
          >
            <div className="single-testimonials-item">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna ali. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>

              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={client1} alt="about" />
                  <div className="title">
                    <h3>Alex Maxwell</h3>
                    <span>CEO at EnvyTheme</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="single-testimonials-item">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna ali. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>

              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={client2} alt="about" />
                  <div className="title">
                    <h3>David Warner</h3>
                    <span>CEO at Envato</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="single-testimonials-item">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna ali. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>
              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={client3} alt="about" />
                  <div className="title">
                    <h3>Sarah Taylor</h3>
                    <span>CEO at ThemeForest</span>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>

      <div className="shape-img1">
        <img src={shape} alt="about" />
      </div>
    </section>
  )
}

export default Testimonios
