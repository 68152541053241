import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import historia1 from "../../assets/images/febos/historia1.png"
import historia2 from "../../assets/images/febos/historia2.png"
import historia3 from "../../assets/images/febos/historia3.png"
import historia4 from "../../assets/images/febos/historia4.png"
import historia5 from "../../assets/images/febos/historia5.png"
import historia6 from "../../assets/images/febos/historia6.png"

const NuestraHistoria = () => {
  let newDate = new Date()
  let year = newDate.getFullYear()
  let monthNumber = newDate.getMonth()
  let monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ]
  let month = monthNames[monthNumber]
  return (
    <section className="history-area ptb-100 bg-fafafb">
      <div className="container">
        <div className="section-title">
          <h2>La historia comienza el año 2011</h2>
        </div>

        <ol className="timeline history-timeline">
          <li className="timeline-block">
            <div className="timeline-date">
              <span>2011</span>
              Octubre
            </div>

            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>

            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>Nacimiento</h3>
                    <p>
                      Los socios fundadores dan vida a Febos, una empresa que se
                      dedica principalmente a dar apoyo a empresas que
                      implementan Facturación Eletrónica.
                    </p>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <img src={historia1} alt="banner" />
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li className="timeline-block">
            <div className="timeline-date">
              <span>2012</span>
              Junio
            </div>

            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>

            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>Primer producto</h3>
                    <p>
                      Con la experiencia adquirida apoyando a nuestros clientes,
                      creamos la primera versión de Febos, un producto que
                      permitía la automatización de procesos relacionados a la
                      facturación electrónica.
                    </p>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <img src={historia2} alt="banner" />
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li className="timeline-block">
            <div className="timeline-date">
              <span>2014</span>
              Marzo
            </div>

            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>

            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>Facturación Electrónica propia</h3>
                    <p>
                      Nace la segunda versión de Febos, la cual permite mediante
                      distintos servicios y tecnologías la emisión de documentos
                      tributarios electrónicos a nuestros clientes.
                    </p>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <img src={historia3} alt="banner" />
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li className="timeline-block">
            <div className="timeline-date">
              <span>2018</span>
              Agosto
            </div>

            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>

            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>Impulsando la Transformación Digital</h3>
                    <p>
                      Utilizando tecnología de punta gracias a Amazon Web
                      Services, nace la tercera versión de nuestro motor de
                      facturación electrónica Febos, esta vez incluyendo nuevos
                      servicios orientados a la transformación digital.
                    </p>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <img src={historia4} alt="banner" />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="timeline-block">
            <div className="timeline-date">
              <span>2020</span>
              Febrero
            </div>

            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>

            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>Escritorio Digital</h3>
                    <p>
                      En el esfuerzo de impulsar la transformación digital tanto
                      de servicios públicos como empresas privada nace
                      Escritorio Digital, con un nuevo paquete de servicios.
                    </p>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <img src={historia5} alt="banner" />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="timeline-block">
            <div className="timeline-date">
              <span>{year}</span>
              {month}
            </div>

            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>

            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>Hoy</h3>
                    <p>
                      Seguimos llevando la transformación digital a nuestros
                      clientes, mejorando y ampliando nuestros servicios cada
                      día.
                    </p>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <img src={historia6} alt="banner" />
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </section>
  )
}

export default NuestraHistoria
