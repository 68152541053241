import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import QuienesSomosContenido from "../components/SobreNosotros/QuienesSomosContenido"
import NuestraHistoria from "../components/SobreNosotros/NuestraHistoria"
import PorqueElegirnos from "../components/SobreNosotros/PorqueElegirnos"
import NuestroEquipo from "../components/SobreNosotros/NuestroEquipo"
import Testimonios from "../components/SobreNosotros/Testimonios"
import Partner from "../components/SobreNosotros/Partner"

const QuienesSomos = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Quienes Somos"
        homePageText="Home"
        homePageUrl="/quienes-somos"
        activePageText="Quienes Somos"
      />
      <QuienesSomosContenido />
      <NuestraHistoria />
      <PorqueElegirnos />
      {/* <Testimonios /> */}
      <NuestroEquipo />
      <Footer />
    </Layout>
  )
}

export default QuienesSomos
