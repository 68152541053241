import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import howItWork from "../../assets/images/febos/porque.png"

const PorqueElegirnos = () => {
  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <h2>¿Por qué elegirnos?</h2>
              <div className="inner-box">
                <div className="single-item">
                  <div className="count-box">1</div>
                  <h3>Tenemos experiencia</h3>
                  <p>
                    Contamos con más de 10 años de experiencia trabajando en
                    servicios públicos y privados, facilitando la creación y
                    gestión de documentos digitales administrativos de empresas.
                    Estamos constantemente perfeccionándonos y siempre
                    actualizados con el mundo digital, entregándote un servicio
                    vigente en la vanguardia tecnológica.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">2</div>
                  <h3>Somos resolutivos</h3>
                  <p>
                    Dada la realidad actual, muchas empresas han tenido que
                    desarrollarse en el mundo digital, es decir, traspasar todos
                    sus documentos, a documentos digitales. No más trámites
                    presenciales, vive la experiencia FEBOS desde tu computador
                    o smartphone, mediantes nuestras plataformas.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">3</div>
                  <h3>Evolucionamos</h3>
                  <p>
                    Porque seguimos mejorando la eficacia y agregando méritos
                    sin costo adicional. No somos el mismo FEBOS de hace un par
                    de años atrás, día a día vamos mejorando y
                    perfeccionándonos, otorgándote un servicio completamente
                    responsivo y a prueba de fallos.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <img src={howItWork} alt="banner" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PorqueElegirnos
